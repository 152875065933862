import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <h1>Welkom bij NB Entertainment</h1>
      <div className="home-content">
        <section className="intro">
          <h2>Nancy Beerens</h2>
          <p>Professionele zangeres voor al uw evenementen</p>
        </section>
        <section className="services">
          <h2>Wat ik aanbied</h2>
          <ul>
            <li>Live optredens</li>
            <li>Achtergrondmuziek</li>
            <li>Karaoke-avonden</li>
            <li>Privé-feesten en bruiloften</li>
          </ul>
        </section>
        <section className="cta">
          <h2>Boek nu uw onvergetelijke muzikale ervaring</h2>
          <button className="cta-button">Neem contact op</button>
        </section>
      </div>
    </div>
  );
}

export default Home;