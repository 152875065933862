import React, { useState, useRef, useEffect } from 'react';

function ContactForm() {
  const [selectedOption, setSelectedOption] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = [
    'Ik zou graag een vraag willen stellen',
    'Boekingsaanvraag doen'
  ];

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="bg-[#E3DFCA] shadow-lg rounded-lg overflow-hidden p-6 max-w-md mx-auto">
      <h2 className="block text[#32312F] mb-1">
        Heeft u een vraag? stel deze dan gerust via onderstaand contactformulier U kunt hier ook direct een boeking aanvraag doen. Kies dan voor Nieuwe Boeking
      </h2>
      <form action="https://formspree.io/f/xldrkwje" method="POST" className="space-y-4">
        
        {/* Dropdown Menu */}
        <div className="relative" ref={dropdownRef}>
          <label htmlFor="optie" className="block text-[#32312F] mb-2">Optie:</label>
          <input
            type="text"
            id="optie"
            name="optie"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            onClick={() => setIsOpen(true)}
            placeholder="Kies een optie"
            className="w-full px-3 py-2 border border-[#D5CEAF] rounded-md focus:outline-none focus:ring-2 focus:ring-[#EEF5DB] text-[#32312F] bg-[#F5F5DC] cursor-pointer"
          />
          {isOpen && (
            <ul className="absolute z-10 w-full mt-1 bg-white border border-[#D5CEAF] rounded-md shadow-lg max-h-60 overflow-auto">
              {options.map((option) => (
                <li
                  key={option}
                  className="px-3 py-2 hover:bg-[#EEF5DB] cursor-pointer text-[#32312F]"
                  onClick={() => handleSelect(option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Name Input */}
        <div>
          <label htmlFor="name" className="block text-[#32312F] mb-1">Name:</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            required 
            className="w-full px-3 py-2 border border-[#D5CEAF] rounded-md focus:outline-none focus:ring-2 focus:ring-[#EEF5DB] text-[#32312F] bg-[#F5F5DC]"
          />
        </div>

        {/* Email Input */}
        <div>
          <label htmlFor="email" className="block text-[#32312F] mb-1">Email:</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            required 
            className="w-full px-3 py-2 border border-[#D5CEAF] rounded-md focus:outline-none focus:ring-2 focus:ring-[#EEF5DB] text-[#32312F] bg-[#F5F5DC]"
          />
        </div>

        {/* Message Input */}
        <div>
          <label htmlFor="message" className="block text-[#32312F] mb-1">Message:</label>
          <textarea 
            id="message" 
            name="message" 
            required 
            rows="4"
            className="w-full px-3 py-2 border border-[#D5CEAF] rounded-md focus:outline-none focus:ring-2 focus:ring-[#EEF5DB] text-[#32312F] bg-[#F5F5DC]"
          ></textarea>
        </div>

        {/* Submit Button */}
        <button 
          type="submit" 
          className="w-full bg-[#32312F] text-[#F5F5DC] py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300"
        >
          Send
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
