import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import './FAQ.css'; // We'll create this file for custom styles

function FAQ() {
  const faqs = [
    {
      question: "Zing je ook verzoekjes?",
      answer: "Mocht je graag willen dat ik je favoriete nummer zing dan wil ik je graag verzoeken om dit op voorhand te melden zodat ik hiernaar kan kijken."
    },
    {
      question: "Verzorg je alles qua geluid etc.?",
      answer: "Ja, ik neem geluidsmateriaal inclusief geluidsman mee op locatie. Indien er op locatie al een geluidsman met apparatuur aanwezig is dan graag in overleg."
    },
    // Add more FAQ items here
  ];

  return (
    <div className="faq-container">
      <h1>FAQ</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <Disclosure as="div" className="faq-item" key={index}>
            {({ open }) => (
              <>
                <Disclosure.Button className="faq-question">
                  <span>{faq.question}</span>
                  <ChevronUpIcon
                    className={`faq-icon ${open ? 'rotate' : ''}`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="faq-answer">
                  {faq.answer}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

export default FAQ;