import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Navbar.css';
import Home from './components/Home';
import About from './components/About';
import MijnAanbod from './components/MijnAanbod';
import FAQ from './components/FAQ';
import ContactForm from './components/contactform';

// Throttle function
const throttle = (func, limit) => {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
}

const NavLink = ({ to, children, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  const handleClick = (e) => {
    e.preventDefault();
    const element = document.getElementById(to.slice(1));
    if (element) {
      const headerOffset = document.querySelector('.sticky-header').offsetHeight;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    if (onClick) onClick();
  };

  return (
    <Link
      to={to}
      className={`nav-link ${isActive ? 'active' : ''}`}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
}

function App() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const closeNav = () => {
    setIsNavExpanded(false);
  };

  const handleScroll = useCallback(throttle(() => {
    const sections = ['home', 'about', 'mijn-aanbod', 'faq', 'contact'];
    let currentSection = '';

    sections.forEach(section => {
      const element = document.getElementById(section);
      if (element && window.scrollY >= element.offsetTop - 200) {
        currentSection = section;
      }
    });

    if (currentSection) {
      window.history.pushState(null, '', `/${currentSection === 'home' ? '' : currentSection}`);
    }
  }, 200), []); // Throttle to once every 200ms

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Router>
      <div className="App">
        <div className="sticky-header">
          <div className="banner-container">
            <img 
              src={process.env.PUBLIC_URL + '/Banner_Final.png'} 
              alt="NB Entertainment" 
              className="banner"
            />
          <img 
    src={process.env.PUBLIC_URL + '/Banner_Final.png'} 
    alt="NB Entertainment" 
    className="mobile-banner"
  />
</div>
      

          <nav className={`custom-navbar ${isNavExpanded ? 'expanded' : ''}`}>
            <button className="navbar-toggle" onClick={toggleNav} title="Open menu">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="toggle-text">Menu</span>
            </button>
            <ul className="nav-menu">
              <li><NavLink to="/" onClick={closeNav}>Home</NavLink></li>
              <li><NavLink to="/about" onClick={closeNav}>About</NavLink></li>
              <li><NavLink to="/mijn-aanbod" onClick={closeNav}>Mijn aanbod</NavLink></li>
              <li><NavLink to="/faq" onClick={closeNav}>FAQ</NavLink></li>
              <li><NavLink to="/contact" onClick={closeNav}>Contact</NavLink></li>
            </ul>
          </nav>
        </div>

        <main className="content">
          <section id="home"><Home /></section>
          <section id="about"><About /></section>
          <section id="mijn-aanbod"><MijnAanbod /></section>
          <section id="faq"><FAQ /></section>
          <section id="contact"><ContactForm /></section>
        </main>
      </div>
    </Router>
  );
}

export default App;